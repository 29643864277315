import hostOmni from '@/config/host';
import axios from "axios";

let webSocketProvider, IndicadoresConnection;

const bearerToken = JSON.parse(localStorage.getItem("login"))?.token;
const callbacks = {};

const start = async () => {
	if (!IndicadoresConnection) {
		webSocketProvider = await axios.get("/websocket-provider").then(response => response.data);
		switch (webSocketProvider) {
			default: break;
			case "SignalR":
				IndicadoresConnection = await import("@microsoft/signalr").then(signalR => {
					let connection = new signalR.HubConnectionBuilder()
						.withUrl(`${hostOmni}/IndicadoresRegistrosHub`, { accessTokenFactory: () => bearerToken })
						.configureLogging(signalR.LogLevel.Error)
						.withAutomaticReconnect()
						.build();

					connection.serverTimeoutInMilliseconds = 180000;
					connection.keepAliveIntervalInMilliseconds = 60000;

					return connection;
				});

				break;
			case "SDWebSocket":
				IndicadoresConnection = await import("@/assets/lib/sd-websocket/SDWebSocket").then(sdWebSocket => {
					return new sdWebSocket.HubConnection()
						.withUrl(`${hostOmni.replace("https://", "wss://")}/IndicadoresRegistrosHub`)
						.withAccessToken(bearerToken)
						.withAutomaticReconnect();
				});

				break;
		}
	}

	IndicadoresConnection.on("Indicadores", callbacks["indicadores"] ?? (() => { }));
	IndicadoresConnection.on("MailingCount", callbacks["mailingCount"] ?? (() => { }));
	IndicadoresConnection.on("ImportacaoNaoIniciada", callbacks["importacaoNaoIniciada"] ?? (() => { }));
	IndicadoresConnection.on("ClienteAdicionado", callbacks["clienteAdicionado"] ?? (() => { }));
	IndicadoresConnection.on("ImportacaoFinalizada", callbacks["importacaoFinalizada"] ?? (() => { }));

	return await IndicadoresConnection.start()
}

const stop = () => {
	IndicadoresConnection.off("Indicadores");
	IndicadoresConnection.off("MailingCount");
	IndicadoresConnection.off("ClienteAdicionado");
	IndicadoresConnection.off("ImportacaoFinalizada");

	return IndicadoresConnection.stop();
}

const onIndicadores = (callback) => {
	callbacks.indicadores = callback;
}

const onMailingCount = (callback) => {
	callbacks.mailingCount = callback;
}

const onClienteAdicionado = (callback) => {
	callbacks.clienteAdicionado = callback;
}

const onImportacaoFinalizada = (callback) => {
	callbacks.importacaoFinalizada = callback;
}

const onImportacaoNaoIniciada = (callback) => {
	callbacks.importacaoNaoIniciada = callback;
}

const comecarOuvirImportacaoCliente = () => {
	return IndicadoresConnection.send("OuvirProgressoImportacaoClientes");
};


export default {
	IndicadoresConnection,
	start,
	stop,
	onIndicadores,
	onMailingCount,
	onClienteAdicionado,
	onImportacaoFinalizada,
	onImportacaoNaoIniciada,
	comecarOuvirImportacaoCliente
}